<template>
  <v-card class="mt-4 pa-5 text-left rounded-lg" elevation="0" @click="$emit('click')">
    <v-row>
      <v-col cols="7">
        <h3 class="text-title --prm --w-bold">{{ name }}</h3>
        <p class="description pt-4 text-medium --c-x-dark">{{ description }}</p>
      </v-col>
      <v-divider vertical class="--border-light-gray mx-2 my-3"></v-divider>
      <v-col class="vertical-child-center">
        <h3 class="text-x-heading --w-bold --prm">
          {{ point | toThousands }}<span class="text-normal">{{ unit }}</span>
        </h3>
      </v-col>
    </v-row>
    <v-img
      v-if="!noPlan"
      :src="image"
      :lazy-src="lazyReservationImgUrl"
      max-height="250"
      class="mt-2"
    />
  </v-card>
<!--  <div class="bg-white mt-3 pa-5 d-flex text-left" @click="$emit('click')">-->

<!--  </div>-->
</template>

<script>
import { lazyImageMixin } from '@/mixins/lazy-image.mixin'

export default {
  name: 'PlanItem',
  mixins: [lazyImageMixin],
  props: {
    plan: Object,
    noPlan: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      priceNoPlan: null
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        const { totalPrice } = await this.$store.dispatch('calculateAccommodationPrice', { noPlan: true })
        this.priceNoPlan = totalPrice
      })
    })
  },
  computed: {
    name () { return this.plan?.title ?? 'プランを利用しない' },
    description () { return this.plan?.description ?? '' },
    image () {
      return this.getImage(this.plan?.imagePublicUrl1, true)
    },
    point () {
      if (this.plan) {
        return this.plan?.prices.reduce((sum, price) =>
          sum + (price.accommodationPrice ?? 0) +
                    (price.accommodationPriceSp ?? 0) +
                    (price.accommodationPriceFp ?? 0),
        0)
      } else {
        return this.priceNoPlan
      }
    },
    unit () {
      return this.$store.getters.newBookingAccommodationPriceUnit()
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
    max-width: 220px;
}
</style>
