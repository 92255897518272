<template>
  <div>
    <v-divider></v-divider>
    <div class="bg-white py-5 px-4 d-flex">
      <div
        class="text-center"
        :class="{ 'cursor-pointer': stage > 1 }"
        @click="stageClick(1, 'home-vacancy-search-reservation-select-plan')"
      >
        <p
          class="item-circle bg-silver text-heading --white ml-4"
          :class="{ 'bg-primary': stage === 1, 'bg-primary-opacity': stage > 1 }"
        >
          1
        </p>
        <p
          class="mt-2 text-default --black --w-regular"
          :class="{ '--prm': stage === 1, '--prm-opacity': stage > 1 }"
        >
          {{ $t('text.stage.planSelection') }}
        </p>
      </div>
      <v-divider class="mt-4" />
      <div
        class="text-center"
        :class="{ 'cursor-pointer': stage > 2 }"
        @click="stageClick(2, 'home-vacancy-search-reservation-confirmation')"
      >
        <p
          class="item-circle bg-silver text-heading --white ml-3"
          :class="{ 'bg-primary': stage === 2, 'bg-primary-opacity': stage > 2 }"
        >
          2
        </p>
        <p
          class="mt-2 text-default --black --w-regular"
          :class="{ '--prm': stage === 2, '--prm-opacity': stage > 2 }"
        >
          {{ $t('text.stage.reservationConfirmation') }}
        </p>
      </div>
      <v-divider class="mt-4" />
      <div
        class="text-center"
        :class="{ 'cursor-pointer': stage > 3 }"
        @click="stageClick(3, 'home-vacancy-search-reservation-enter-information')"
      >
        <p
          class="item-circle bg-silver text-heading --white ml-3"
          :class="{ 'bg-primary': stage === 3, 'bg-primary-opacity': stage > 3 }"
        >
          3
        </p>
        <p
          class="mt-2 text-default --black --w-regular"
          :class="{ '--prm': stage === 3, '--prm-opacity': stage > 3 }"
        >
          {{ $t('text.stage.dataEntry') }}
        </p>
      </div>
      <v-divider class="mt-4" />
      <div class="text-center">
        <p
          class="item-circle bg-silver text-heading --white ml-1"
          :class="{ 'bg-primary': stage === 4 }"
        >
          4
        </p>
        <p
          class="mt-2 text-default --black --w-regular"
          :class="{ '--prm': stage === 4 }"
        >
          {{ $t('text.stage.decision') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stage',
  props: {
    stage: {
      type: Number,
      default: 1
    }
  },
  methods: {
    stageClick (num, routerName) {
      if (num < this.stage) {
        this.$router.push({ name: routerName })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-circle {
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
</style>
